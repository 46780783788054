import React from 'react'
import StepOne from './step-one'
import StepTwo from './step-two'
import StepThree from './step-three'

const steps = [
  { name: 'About You', renderComponent: props => <StepOne {...props} /> },
  { name: 'Your Equipment', renderComponent: props => <StepTwo {...props} /> },
  { name: 'Service Needed', renderComponent: props => <StepThree {...props} /> },
]

export { steps }
