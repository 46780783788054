import React from 'react'
import styled from 'styled-components'
import HeroImage from './hero-image'
import Breadcrumbs from './breadcrumbs'
import { HeroImageTitleType } from './types'

const HeroImageTitle = ({ bg, breadcrumbs, children, title, ...props }) => {
  return (
    <HeroImage bg={bg} {...props}>
      <H1>{title}</H1>
      {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      {children}
    </HeroImage>
  )
}

HeroImageTitle.propTypes = HeroImageTitleType

const H1 = styled.h1`
  color: #fff;
  font-size: 2.25em;
  margin: 0;

  @media (min-width: 800px) {
    font-size: 3em;
  }
`

export default HeroImageTitle
