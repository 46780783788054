import React from 'react'
import * as PropTypes from 'prop-types'
import Location from 'components/forms/elements/location'
import Textarea from 'components/forms/elements/textarea'

const StepThree = ({ updateValidationSet, locationOverride }) => {
  return (
    <>
      {locationOverride && (
        <div style={{ display: 'none' }}>
          <label>
            Location
            <input type='text' name='Location' value={locationOverride} readOnly />
          </label>
        </div>
      )}
      {locationOverride === '' && <Location updateValidationSet={updateValidationSet} required />}
      <Textarea
        updateValidationSet={updateValidationSet}
        label='Comments'
        name='Comments'
        validationError='Please enter a valid message.'
        required
      />
    </>
  )
}

StepThree.propTypes = {
  updateValidationSet: PropTypes.func.isRequired,
  locationOverride: PropTypes.string,
}

StepThree.defaultProps = {
  locationOverride: '',
}

export default StepThree
