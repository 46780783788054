import React from 'react'
import * as PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Select from 'components/forms/elements/select'
import noop from 'utils/noop'

const Location = ({
  children,
  label,
  locations,
  name,
  placeholderText,
  required,
  updateValidationSet,
  ...props
}) => {
  const { allSanityLocation } = useStaticQuery(query)
  const data = locations || allSanityLocation
  return (
    <Select
      updateValidationSet={updateValidationSet}
      required={required}
      name={name}
      label={label}
      {...props}
    >
      <option value='' disabled={required}>
        {placeholderText}
      </option>
      {data.nodes.map(node => (
        <option value={node.value || node.title} key={node.title}>
          {node.title}
        </option>
      ))}
      {children}
    </Select>
  )
}

Location.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholderText: PropTypes.string,
  required: PropTypes.bool,
  updateValidationSet: PropTypes.func,
}

Location.defaultProps = {
  label: 'Location',
  name: 'Location',
  placeholderText: 'Select a location',
  required: false,
  updateValidationSet: noop,
}

const query = graphql`
  {
    allSanityLocation(
      sort: { fields: title, order: ASC }
      filter: { status: { ne: "permanently-closed" }, retailLocation: { eq: true } }
    ) {
      nodes {
        title
        value: salesforceTitle
      }
    }
  }
`

export default Location
