import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { HeroImageType } from './types'

const HeroImage = ({ children, image, ...props }) => {
  return (
    <Container {...props}>
      <ImageContainer>
        <Image image={image?.childImageSharp?.gatsbyImageData} alt='' />
      </ImageContainer>
      {children && (
        <Wrapper>
          <Content>{children}</Content>
        </Wrapper>
      )}
    </Container>
  )
}

HeroImage.propTypes = HeroImageType

const Container = styled.div`
  height: 250px;
  position: relative;
  width: 100%;

  @media (min-width: 800px) {
    height: 350px;
  }
`

const Wrapper = styled.div`
  height: 100%;
  margin: auto;
  max-width: 85%;
  position: relative;
  z-index: 1;

  @media (min-width: 800px) {
    width: 75%;
  }
`

const Content = styled.div`
  bottom: 15%;
  position: absolute;
  width: 100%;
`

const ImageContainer = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`

const Image = styled(GatsbyImage)`
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  width: 100%;
`

export default HeroImage
