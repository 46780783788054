import React from 'react'
import * as PropTypes from 'prop-types'
import Input from 'components/forms/elements/input'
import { Column, Grid } from 'components/grid'

const StepTwo = ({ updateValidationSet }) => {
  return (
    <Grid>
      <Column columns='1/3'>
        <Input
          updateValidationSet={updateValidationSet}
          label='Equipment Make'
          name='Equipment Make'
          validationError='Please enter a valid equipment make.'
          required
        />
      </Column>
      <Column columns='1/3'>
        <Input
          updateValidationSet={updateValidationSet}
          label='Equipment Model'
          name='Equipment Model'
          validationError='Please enter a valid equipment model.'
          required
        />
      </Column>
      <Column columns='1/3'>
        <Input
          updateValidationSet={updateValidationSet}
          label='Serial Number'
          name='Serial Number'
          validationError='Please enter a valid serial number.'
        />
      </Column>
    </Grid>
  )
}

StepTwo.propTypes = {
  updateValidationSet: PropTypes.func.isRequired,
}

export default StepTwo
